import React from 'react'
import { FAQPage } from '@pages/components'
import { Container } from '@components'
import './FAQ.scss'

const FAQ = () => {
  return (
    <section className="FAQ">
      <Container>
        <div className={'banner-faq'}>
          <div className="banner-faq__title"> Frequently Asked Questions</div>
          <div className="banner-faq__description">Learn about Buying and Selling on the TourDay Golf marketplace.</div>
        </div>

        <div className={'FAQ-grid'}>
          <FAQPage.HeadingsSection />
          <FAQPage.QuestionsSection />
        </div>
      </Container>
    </section>
  )
}

export default FAQ
